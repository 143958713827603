import { RecordedAction } from "../pages/teach/recordedAction";

export interface SourceNode {
  fileName: string;
  fileType: string;
  fileUrl: string;
};

export interface Message {
  id: number;
  text: string;
  sender: string;
  isLoading?: boolean;
  isDraft?: boolean;
  sourceNodes?: SourceNode[];
  instructions?: RecordedAction[];
};

export enum EnumReviewType {
  LIKE = 'LIKE',
  DISLIKE = 'DISLIKE'
};
