import { useState } from 'react';
import { Box, CircularProgress, Tooltip, IconButton } from '@mui/material';
import { forgetDeleteDocumentation, reingestDocuments } from '../../../api/patiaAI';
import { useSnackbar } from '../../../context/SnackbarContext';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useUserContextController } from '../../../context/UserContext';

interface DocumentListActionsProps {
  document: any;
  afterDelete: () => void;
  isDeleting: boolean;
  setIsDeleting: (value: boolean) => void;
}

const DocumentListActions = (props: DocumentListActionsProps) => {
  const [userContext] = useUserContextController();
  const { user, slackCredentials, notionCredentials, googleCredentials, agentName } = userContext;
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [deletingThisItem, setDeletingThisItem] = useState(false);
  const disableButton = props.isDeleting || props.document.actor_id !== user?.user_id;
  const { showSnackbar } = useSnackbar();
  
  const viewDocument = (url: string) => {
    window.open(url, "_blank");
  }
  
  const getAccessToken = (doc_type: string) => {
    switch (doc_type) {
      case 'notion_pages':
        return notionCredentials?.access_token || '';
      case 'slack_channels':
        return slackCredentials?.access_token || '';
      case 'gdoc':
        return googleCredentials?.access_token || '';
      default:
        return '';
    }
  }

  const handleDeleteIngestion = async () => {
    props.setIsDeleting(true);
    setDeletingThisItem(true);
    const delete_flag = false;
    forgetDeleteDocumentation(agentName || '', props.document.id, delete_flag)
      .then(() => {
        props.setIsDeleting(false);
        setDeletingThisItem(false);
        showSnackbar('Successfully deleted', 'success');
        props.afterDelete();
      })
      .catch(() => {
        props.setIsDeleting(false);
        setDeletingThisItem(false);
        showSnackbar('Failed to delete', 'error');
      });
  };

  const handleRefreshIngestion = async () => {
    setIsRefreshing(true);
    reingestDocuments(agentName || '', getAccessToken(props.document.doc_type), [props.document])
      .then(() => {
        setIsRefreshing(false);
        showSnackbar('Successfully reingested', 'success');
      })
      .catch(() => {
        setIsRefreshing(false);
        showSnackbar('Failed to reingest', 'error');
      });
  };

  const getRefreshButtonTitle = () => {
    if (getAccessToken(props.document.doc_type) === '') {
      return 'Cannot be reingested';
    } else if (disableButton) {
      return 'You are only allowed to reingest skills that you have created';
    } else if (props.isDeleting || deletingThisItem) {
      return 'Refresh not allowed while deletion is in progress';
    } else {
      return null;
    }
  };

  const getDeleteButtonTitle = () => {
    if (isRefreshing) {
      return 'Delete not allowed while refresh is in progress';
    } else if (props.document.doc_type === 'dokai_skill') {
      return 'Unable to delete';
    } else {
      return null;
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Tooltip title="View" placement="top">
        <IconButton
          sx={{
            color: '#49BAFD',
            cursor: 'pointer',
            padding: '4px'
          }}
          onClick={() => viewDocument(props.document.url)}
        >
          <VisibilityIcon sx={{ width: '20px', height: '20px' }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={getRefreshButtonTitle() || 'Refresh'} placement="top">
        <span>
          <IconButton
            sx={{
              color: (
                isRefreshing || deletingThisItem ||
                props.isDeleting || disableButton ||
                getAccessToken(props.document.doc_type) === ''
              ) ? '#CCCCCC' : '#49BAFD',
              padding: '4px'
            }}
            disabled={
              isRefreshing || deletingThisItem ||
              props.isDeleting || disableButton ||
              getAccessToken(props.document.doc_type) === ''
            }
            onClick={handleRefreshIngestion}
          >
            {isRefreshing ? <CircularProgress size={20} /> : <RefreshIcon sx={{ width: '20px', height: '20px' }} />}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={getDeleteButtonTitle() || 'Delete'} placement="top">
        <span>
          <IconButton
            sx={{
              color: (props.isDeleting || isRefreshing || disableButton || deletingThisItem) ? '#CCCCCC' : '#DC3545',
              padding: '4px'
            }}
            disabled={props.isDeleting || isRefreshing || disableButton || deletingThisItem}
            onClick={handleDeleteIngestion}
          >
            {deletingThisItem ? <CircularProgress size={20} /> : <DeleteOutlineIcon sx={{ width: '20px', height: '20px' }} />}
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export default DocumentListActions;
