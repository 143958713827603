import { useState } from 'react';
import { Avatar, Box, Button, CircularProgress, Typography } from "@mui/material";
import { isChromeExtApp } from "../../utils/chromeHelper";
import { GOOGLE_API_CUSTOM_SCOPE, GOOGLE_AUTH_BASE_URL } from "../../api/constants";
import WavingHandIcon from '@mui/icons-material/WavingHand';
import SiteIcon from '../../assets/dokai-icon.svg';
import { login, setCredentials } from '../../api/userProfile';
import { GOOGLE_ACCOUNT_TYPE } from '../../types/userCredentials';
import { useCookies } from 'react-cookie';
import { useSnackbar } from '../../context/SnackbarContext';
import { setGoogleCredentials, useUserContextController } from '../../context/UserContext';
import { getPublicToken } from '../../api/environmentVariables';
import { URL_PATHNAMES } from '../../components/layout/index';
import GoogleGLogo from '../../assets/GoogleGLogo.svg';
import Bugsnag from '@bugsnag/js';


const LoginComponent = () => {
  const [, setCookie] = useCookies(['user_session_token']);
  const [, userDispatch] = useUserContextController();
  const [loading, setLoading] = useState(false);
  const [unregisteredChromeAppId, setUnregisteredChromeAppId] = useState(false);
  const { showSnackbar } = useSnackbar();

  const onSignIn = (token: string) => {
    if (token) {
      login(token).then((loginData: any) => {
        const userData = loginData.user;
        const sessionData = loginData.session;
        const accountType = isChromeExtApp()
          ? GOOGLE_ACCOUNT_TYPE.EXTENSION
          : GOOGLE_ACCOUNT_TYPE.WEB_APP;
        if (sessionData.session_token) {
          const userCredentials = {
            user_id: String(userData.user_id),
            credentials: {
              google: {
                access_token: sessionData.google_oauth_access_token,
                refresh_token: sessionData.google_oauth_refresh_token
              }
            }
          }

          setCredentials(accountType, userCredentials).then(() => {
            localStorage.setItem('token', sessionData.session_token);
            setCookie('user_session_token', sessionData.session_token, { path: "/" });
            setGoogleCredentials(userDispatch, {
              access_token: sessionData.google_oauth_access_token,
              refresh_token: sessionData.google_oauth_refresh_token
            });
            showSnackbar("Login Successful", 'success');
          }).catch(() => {
            showSnackbar("Login failed", "error");
            setLoading(false);
            Bugsnag.notify("Failed to set user credentials on login");
          });
        }
      }).catch(() => {
        showSnackbar("Login failed", "error");
        setLoading(false);
        Bugsnag.notify("User failed to login");
      });
    } else {
      setLoading(false);
      showSnackbar("Failed to login, token is NULL", "error");
    }
  }

  const onClick = () => {
    setLoading(true);
    // All redirect url should be registered in Stytch dashboard
    const loginRedirectUrl = isChromeExtApp() ? chrome.identity.getRedirectURL() : `${window.location.origin}${URL_PATHNAMES.HOME}`;
    const signupRedirectUrl = isChromeExtApp() ? chrome.identity.getRedirectURL() : `${window.location.origin}${URL_PATHNAMES.HOME}`;

    // TODO: url stored as env or in backend
    const customScopes = GOOGLE_API_CUSTOM_SCOPE;

    const extractToken = (url: string) => {
      const urlParams = new URLSearchParams(url);
      return urlParams.get('token');
    }

    if (isChromeExtApp()) {
      getPublicToken()
      .then((token: string) => {
        if (token) {
          const publicToken = token;
          const url = `${GOOGLE_AUTH_BASE_URL}?public_token=${publicToken}&login_redirect_url=${loginRedirectUrl}&signup_redirect_url=${signupRedirectUrl}&custom_scopes=${customScopes}`;
          chrome.identity.launchWebAuthFlow({
            'url': url,
            'interactive' : true  
          }).then((responseUrl?: string) => {
            if(!responseUrl) return;
            onSignIn(extractToken(responseUrl) as string);
          }).catch((error) => {
            setLoading(false);
            console.error("Error: ", error);
            setUnregisteredChromeAppId(true);
          });
        } else {
          setLoading(false);
          showSnackbar("Sign in failed, please try again later", "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        showSnackbar(`Error: ${error}`, "error");
      })
    } else {
      // Flag to see if user closed popup preemptively without going thru login process.
      // This includes creating new account process but getting the 'unauthorized' view
      let popupClosedPreemptively = true;
      const publicToken = process.env.REACT_APP_GOOGLE_PUBLIC_TOKEN;
      const url = `${GOOGLE_AUTH_BASE_URL}?public_token=${publicToken}&login_redirect_url=${loginRedirectUrl}&signup_redirect_url=${signupRedirectUrl}&custom_scopes=${customScopes}`;
      const popup = window.open(url, "popup", "popup=true");
      const checkPopup = setInterval(() => {
        try {
          // TODO: remove try catch, this happen when the URL is GDOCS url
          if (popup && popup.window.location.href.includes(window.location.origin)) {
            popupClosedPreemptively = false;
            onSignIn(extractToken(popup.window.location.href) as string);
            popup.close();
          } 
        } catch (error) {
          // Entering this error is expected while the url is in the unexpected loc
        }

        if (!popup || !popup.closed) return;
        clearInterval(checkPopup);
        // Set loading false and show snackbar but does not throw error on bugsnag
        if (popupClosedPreemptively) {
          setLoading(false);
          showSnackbar("Failed to login", "error");
        }
     }, 1000);
    }
  };

  if (unregisteredChromeAppId) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography sx={{
          fontWeight: '400', 
          fontSize: '12px',
          textAlign: 'center',
        }}>
          Please send email with url below to gerry@dokai.ai to get your extension registered.
        </Typography>
        <Typography sx={{
          fontWeight: '400', 
          fontSize: '12px',
          textAlign: 'center',
        }}>
          {chrome.identity.getRedirectURL()}
        </Typography>
        
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: '400px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        src={SiteIcon}
        alt="dokai-icon"
        style={{
          height: '48px',
          width: '48px'
        }}
      />
      <Typography component="div" sx={{ margin: '6px', fontWeight: '600', lineHeight: '24px', fontSize: '16px'}} >
        <Box display="flex" alignItems="center" justifyContent="center">
          Hi, Dokai here <WavingHandIcon sx={{ color: '#FBDC34', marginLeft: '5px', alignSelf: 'center' }} />
        </Box>
      </Typography>
      <Typography sx={{ maxWidth: '300px', margin: '6px', fontWeight: '400', fontSize: '12px', lineHeight: '18px', textAlign: 'center' }}>
        Unlock the full potential of your browsing experience with
        <span style={{ color: '#49BAFD' }}> AI-powered </span>
        insights and seamless assistance.
      </Typography>
      <Button
        sx={{
          width: '320px',
          height: '40px',
          borderTopRightRadius: '20px',
          borderBottomRightRadius: '20px',
          borderTopLeftRadius: '20px',
          borderBottomLeftRadius: '20px',
          backgroundColor: '#49BAFD',
          border: '1px solid #49BAFD',
          textTransform: 'unset',
          fontWeight: '600',
          fontSize: '12px',
          lineHeight: '24px',
          color: 'white !important',
          ":hover": {
            color: '#49BAFD !important',
            backgroundColor: 'white'
          },
          pointerEvents: loading ? 'none' : 'auto'
        }}
        disabled={loading}
        onClick={onClick}
        endIcon={loading ? <CircularProgress sx={{color: 'white'}} size={20} /> : null}
      >
        <Avatar
          src={GoogleGLogo}
          sx={{
            height: '20px',
            width: '20px',
            marginRight: '10px',
            fontSize: '20px',
            alignSelf: 'center'
          }}
        />
        Log in with Google
      </Button>
    </Box>
  );
}

export default LoginComponent;