import { Button } from '@mui/material';
import { RecordedAction } from '../../pages/teach/recordedAction';
import { CHROME_EXTENSION_ID } from '../../api/constants';
import AlertDialog from '../dialog/AlertDialog';
import { useState } from 'react';

interface StartInteractiveGuidanceButtonProps {
  recordedActions: RecordedAction[];
}

const StartInteractiveGuidanceButton = (props: StartInteractiveGuidanceButtonProps) => {
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const onClick = () => {  
    chrome.runtime.sendMessage(CHROME_EXTENSION_ID, {type: 'checkDokaiExtInstalled'}, response => {
      if (chrome.runtime.lastError) {
        // dokai extension is not installed
        setOpenAlertDialog(true);
      } else {
        // dokai extension is installed
        chrome.runtime.sendMessage(
          CHROME_EXTENSION_ID,
          {type: 'startInteractiveGuidance', recordedActions: props.recordedActions}
        );   
      }
    });
  };



  return (<>
    <AlertDialog open={openAlertDialog} onClose={() => setOpenAlertDialog(false)} />
    <Button 
      sx={{
        borderRadius: 3,
        backgroundColor: "#31B1FD",
        fontSize: "10px",
        fontWeight: "600",
        my: 1
      }}
      variant="contained"
      color="primary" 
      onClick={onClick} 
    >Start guidance</Button>
  </>);
};

export default StartInteractiveGuidanceButton;