import { Box } from "@mui/material";
import { useRef } from "react";

import RecordedActionCard from "../learnMode/RecordedActionCard";
import { RecordedAction } from "../../pages/teach/recordedAction";
import interactiveGuidanceForStep from "./interactiveGuidance";
import { isChromeExtApp } from "../../utils/chromeHelper";
import { useSnackbar } from "../../context/SnackbarContext";

interface StepByStepListProps {
  steps: RecordedAction[];
  interactiveGuidanceStep: number;
  setInteractiveGuidanceStep: (step: number) => void;
}

const StepByStepList = (props: StepByStepListProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { showSnackbar } = useSnackbar(); 
  
  return (
    <Box sx={{
      py: 2,
      borderBottom: '1px solid #e0e0e0',
    }}>
      <Box 
      ref={scrollRef}
      sx={{
        maxHeight: '200px',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        // px: 10,
      }}>
        {props.steps.map((step, idx) => (
          <RecordedActionCard
            idx={idx}
            interactiveGuidanceStep={props.interactiveGuidanceStep}
            recordedAction={step}
            showScreenshot={false}
            onClick={() => {
              if(isChromeExtApp()) {
                props.setInteractiveGuidanceStep(idx);
                interactiveGuidanceForStep(step);
              } else {
                showSnackbar('Interactive guidance is only available in Extension', 'error');
              }
            }}
          />  
        ))}
      </Box>
    </Box>
  );
}

export default StepByStepList;