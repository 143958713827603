// export const BASE_URL = "http://localhost:8000";
export const BASE_URL = "https://patia-ai-5d4eafb83866.herokuapp.com";
// export const WEB_BASE_URL = "http://localhost:3000";
export const WEB_BASE_URL = "https://app.dokai.ai";
export const SLACK_AUTH_BASE_URL = "https://slack.com/oauth/v2/authorize";
export const GOOGLE_AUTH_BASE_URL = 'https://test.stytch.com/v1/public/oauth/google/start';
// export const STYTCH_SLACK_AUTH_BASE_URL = 'https://test.stytch.com/v1/public/oauth/slack/start';
export const NOTION_AUTH_BASE_URL = 'https://api.notion.com/v1/oauth/authorize';
export const GOOGLE_API_CUSTOM_SCOPE = 'https://www.googleapis.com/auth/drive.readonly'
export const SLACK_USER_SCOPE = "users:read,users:read.email,channels:read,channels:history"
export const DEFAULT_AGENT_NAME = ""
export const CHROME_EXTENSION_ID = "bknpanghphmdmlcpdbebcdekgghmohbl";
export const CHROME_EXTENSION_URL = `https://chromewebstore.google.com/detail/dokai-beta/${CHROME_EXTENSION_ID}`;