import { RecordedAction } from "../pages/teach/recordedAction";
import { BASE_URL } from "./constants";

interface Skill {
  id: string;
  context: string;
  additional_context: string;
  create_ts: string;
  recorded_actions: RecordedAction[];
  audio_segments: Record<string, string>;
}

interface SkillSummary {
  id: string;
  context: string;
  create_ts: string;
}

const createNewSkill = async (
  user_id: string,
  context: string,
  additional_context: string,
  recorded_actions: RecordedAction[],
  audioId?: string
) => {
  const res = await fetch(`${BASE_URL}/skill?user_id=${user_id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ 
      context,
      additional_context,
      create_ts: new Date().toISOString(),
      recorded_actions,
      audioId
    })
  });

  const skill_data = await res.json();
  
  return skill_data;
};

const updateSkill = async (
  skill_id: string,
  skill: Skill,
) => {
  const res = await fetch(`${BASE_URL}/skill/${skill_id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(skill)
  });

  const skill_data = await res.json();
  
  return skill_data;
}

const getSkill = async (skillId: string) => {
  const res = await fetch(`${BASE_URL}/skill/${skillId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  return res.json();
};

const getSkillsCreateByUser = async (userId: string) => {
  // TODO: Change to skill object instead of IDs
  const res = await fetch(`${BASE_URL}/user/${userId}/skill_ids`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  return res.json();
};

const getSkillsSummaryCreateByUser = async (userId: string, pageSize: number, offset: number) => {
  const res = await fetch(`${BASE_URL}/user/${userId}/skills_summary_v2?pageSize=${pageSize}&offset=${offset}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  const responseData = await res.json();

  return {
    skill_list: responseData.skill_list || [],
    total_count: responseData.total_count || 0
  };
};

const getSkillAnalytics = async (skillId: string) => {
  const res = await fetch(`${BASE_URL}/skill/${skillId}/analytics`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  return res.json();
}

const getSkillAudio = async (skillId: string) => {
  const res = await fetch(`${BASE_URL}/skill/${skillId}/audio`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  if(res.status !== 200) {
    throw new Error('Failed to download audio ZIP archive');
  } 

  return res.blob();
}

export {
  Skill,
  SkillSummary,
  createNewSkill,
  updateSkill,
  getSkill,
  getSkillsCreateByUser,
  getSkillsSummaryCreateByUser,
  getSkillAnalytics,
  getSkillAudio,
};
